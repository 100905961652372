/* src/components/AnimatedArrowButton.css */
/* src/components/AnimatedArrowButton.css */
.animated-arrow-button {
    background-color: #ffffff00;
    border: 2px solid white;
    border-radius: 1rem;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    z-index: 10;
    bottom: 20px;
    right: 50%;
    transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;
    animation: bounceArrow 2s infinite;
  }
  
  @keyframes bounceArrow {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .animated-arrow-button img {
    width: 70px; /* Adjust the width of the PNG icon */
    height: 70px; /* Adjust the height of the PNG icon */
    filter: invert(1); /* Invert the color of the PNG icon (optional) */
  }
  
  .animated-arrow-button:hover {
    transform: scale(1.1);
  }
  
@media (max-width: 767px) {
  .animated-arrow-button img {
  display: none;
  }
}
