 /* *{
  border: 1px solid white;
}  */
/*****************************************Scrollbar styles*****************************************/
::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

scrollbar-width: thin;
scrollbar-color: #888 #f1f1f1;

scrollbar-base-color: #888;
scrollbar-arrow-color: #f1f1f1;
scrollbar-face-color: #f1f1f1;
scrollbar-highlight-color: #f1f1f1;
scrollbar-shadow-color: #f1f1f1;
scrollbar-dark-shadow-color: #888;


/***************************************** Global Styles *****************************************/
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Gothic+A1:wght@100;200;300&family=Grape+Nuts&family=Mansalva&family=Michroma&display=swap');
html {
  scroll-behavior: smooth;
}

h1{
  font-family: 'Gothic A1', sans-serif;
  font-weight: bold;
  font-size: 6rem;
  overflow: hidden;
  position: relative;
  color: white !important;
  white-space: nowrap;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  transition: font-family 0.5s ease-in-out;
  z-index: ;
}


h4{
  font-family: 'Gothic A1', sans-serif;
  font-weight: 300;
  color: #000;
  font-size: 1rem;
  position: relative;
  text-align: center;
}
h1:hover{
  font-family: 'Grape Nuts', cursive;
  font-weight: 800;
  font-size: 10rem;
  margin-top: -1rem;
  margin-left: -2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
h2 {
  font-family: 'Gothic A1', sans-serif;
  font-weight: 200;
  font-size: 2rem;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

h2::after {
  content: "Developer";
  font-weight: bold;
  display: inline-block;
  width: 100%;
  color: white;
}

h2:hover::after {
  content: "DESINGER";
  font-family: 'Grape Nuts', cursive;
  font-weight: 600;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.322);
  margin-top: -0.8rem;
  background: linear-gradient(90deg,#ffd000 0%, #ff00bf 100%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  animation: hueRotate 7s alternate infinite;
}
@keyframes hueRotate {
  0% {
      filter: hue-rotate(279deg);
  }
  50% {
      filter: hue-rotate(197deg);
  }
  100% {
      filter: hue-rotate(12deg);
  }
}

h5{
  font-family: 'Michroma', sans-serif;
  font-weight: 100;
  color: white;
  font-size: 5rem; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

body{
  width: 99%;
  height: 100vh;
  background-image: url('./assets/blurry.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient( to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.0));
}
p {
  color: #fff;
  font-size: 1rem;
}

h1 {
  font-size: 8rem;
  color: #fff;
}
h3{
  color: white;
}
h2 {
  padding: 1rem;
  margin-left: 1rem;
  font-size: 3rem;
  color: #fff;
}

@media (max-width: 767px) {
  p {
    font-size: 0.8rem; 
  }

  h1 {
    font-size: 3rem; 
  }

  h1:hover{
    font-family: 'Mansalva', cursive;
    font-size: 3rem;
    margin-left: -1rem;
  }

  h2 {
    font-size: 2rem; 
  }
  body{
    width: 99%;
    height: 100vh;
    background-image: url('./assets/blurry.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
  body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    z-index: -2;
    background: linear-gradient( to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.0));
  }
}


/*****************************************First Page Styles*****************************************/

.main {
  margin-top: -1rem;
  height: 98vh;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.new-card{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
  z-index: 2;
  display: inline-block;
  margin-left: -25%;
  margin-top: -10%;
  padding-top: 10%;
  margin-bottom: 20%;
}
h3{
  font-family: 'Michroma', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  overflow: hidden;
  position: relative;
  color: black;
  white-space: nowrap;
  margin-top: 1rem;
  transition: margin-top 0.5s ease-in-out;
}
.card h3{
  background-color: #0057b300;
  padding: 4%;
  margin: 0 -25%;
  font-size: 1rem !important;
  overflow: hidden;
  position: relative;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  margin-top: -1rem;
  transition: margin-top 0.5s ease-in-out;
}
.card:hover h3 {
  display: none;
}


.card {
  background-color: #00000063;
  backdrop-filter: blur(50px);
  transform: translate3d(0, 0, 0);
-ms-transform: translate3d(0, 0, 0);
  padding: 2%;
  margin: -25% 2% 20% 60%;
  width: 30%;
  height: 22rem;
  border-radius: 2rem;
  transition: transform 0.5s ease-in-out, height 0.5s ease-in-out, width 0.5s ease-in-out, background-color 0.5s ease-in-out;
  cursor: pointer;
  position: relative;
  overflow: hidden;

}

.card:hover,
.card:focus {
  border: 1px solid rgba(0, 0, 0, 0.845);
  padding: 2%;
  margin: -25% 2% 20% 60%;
  width: 30%;
  height: 22rem;
  box-shadow: 0 2px 30px rgb(0, 0, 0);
  transform: scale(1.1) translateZ(0);
  background: linear-gradient(90deg, #ff0000 0%, #80ff00 100%) !important;
  animation: hueRotate 7s alternate infinite;
}
@keyframes hueRotate {
    0% {
        filter: hue-rotate(279deg);
    }
    50% {
        filter: hue-rotate(197deg);   
    }
    100% {
        filter: hue-rotate(12deg);
    }
}

.card:before {
  content: "";
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 1;
}

.card:hover:before,
.card:focus:before {
  opacity: 1;
}

.card-content {
  opacity: 1;
  transform: translateY(20px);
  transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
}

.card:hover .card-content,
.card:focus .card-content {
  opacity: 1;
  transform: translateY(0);
}

.card h2 {
  margin-bottom: 1rem;
  color: #333;
}

.card p {
  color:#fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
@media only screen and (max-width: 768px) {
    .main {
      width: 101%;
      height: 105vh;
      /* overflow: hidden; */
    }
  .card {
    width: 90%; 
    padding: 10% 3% ;
  }
  .new-card {
    margin-left: 0;
    margin-top: -30%;
    padding-top: 0;
    margin-bottom: 0;
  }
  .card {
    border-radius: 1rem;
    border: none;
    padding: 5% ;
    margin: 10% 10% 2% 10%;
    width: 80%;
    height: 20rem;
    max-height: 1000px;
    transition: transform 0.5s ease-in-out, height 0.5s ease-in-out, width 0.5s ease-in-out, background-color 0.5s ease-in-out;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 10;
    background-color: #00000073;
    backdrop-filter: blur(50px);
    transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  }
  .main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0); 
  }
  .main h3{
    display: none;
  }
  h3{
    font-family: 'Michroma', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    overflow: hidden;
    position: relative;
    color: black;
    white-space: nowrap;
    margin-top: 1rem;
    transition: margin-top 0.5s ease-in-out;
  }
  .card:hover h3 {
    margin-top: 1rem;
    color: white;
    font-family: 'Mansalva', cursive;
  }
  .card-content {
    opacity: 1;
  }
  .card:hover,
  .card:focus {
    border-radius: 1rem;
    border: none;
    padding: 5% ;
    margin: 10% 10% 2% 10%;
    width: 80%;
    height: 20rem;
    max-height: 1000px;
    transition: transform 0.5s ease-in-out, height 0.5s ease-in-out, width 0.5s ease-in-out, background-color 0.5s ease-in-out;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    perspective: 1000px;
    background: linear-gradient(90deg, #ff0000 0%, #80ff00 100%) !important;
    animation: hueRotate 7s alternate infinite;
  }
  @keyframes hueRotate {
      0% {
          filter: hue-rotate(279deg);
      }
      50% {
          filter: hue-rotate(197deg);   
      }
      100% {
          filter: hue-rotate(12deg);
      }
  }
}
/****************************************** Second page Styles ******************************************/

.skills .row{
  padding-left: 2rem;
}
.skills {  
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(118, 15, 162));
  background-size: cover;
  background-position: center;
  width: 101%;
  height: 100vh;
  margin-top: -7%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card-image{
  width: 110%;
  height: 23rem;
  margin-left: -1rem;
  margin-top: -2rem;
  background-size: cover;
  background-position: center;
}
.card-skills h3{
  font-size: 1.5rem !important;
}
.skills .container{
  background-color: #ffffff00;
  border-radius: 2rem;
  margin: 7% auto;
  height: 80%;
  width: 70%;
  max-width: 200vh;
}
.card-skills {
  flex: 0 0 calc(33.33% - 40px);
  height: 60%;
  width: 50%;
  margin: 1%;
  background-size: cover;
  background-position: center;
  border-radius: 1rem;  
  z-index: 1;
  position: relative;;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.377);
  transition: transform 0.3s ease-in-out;
}

.card-skills:hover,
.card-skills:focus {
  transform: scale(1.05);
}

.card-skills .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.card-skills h3 {
  color: #fff;
  font-size: 2rem;
  margin-top: 10rem;
  text-align: center;
  margin: 0;
  padding: 10px;
}
.card-skills p {
  color: #fff;
  font-size: 1rem;
  text-align: center;
  margin: 0;
  padding: 10px;
}
.card-skills-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.55);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  text-align: center;
}


.card-skills:hover .card-skills-content{
  opacity: 1;
}
.card-skills .button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.card-skills .button-container button {
  padding: 0.5rem 1rem;
  background-color: #ffffff00;
  color: #ffffff;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out, color 0.3s ease-in-out;
}

.card-skills .button-container button:hover {
  background-color: #ffffff;
  color: #000000;
}

@media (max-width: 767px) {
  .card-start-project h3 {
    color: #000 !important;
  }
  .skills {
    display: flex;
    flex-wrap: wrap;
    height: 140rem;
    justify-content: center;
    padding-bottom: 20rem;
    padding-top: 42rem;
    padding-left: 1rem;
  }
  .skills .row{
    padding-left: 0rem;
    margin-left: -7%;
  }
  .card-skills {
    flex: 0 0 100%;
    max-width: 90%;
    margin: 2% 5%;
    height: 20rem;
    width: 15rem;
  }
  .card-skills h3 {
    color: #fff;
    font-size: 1.3rem !important;
    margin-top: 10rem;
    text-align: center;
    margin: 0;
    padding: 2%;
  }
  .card-skills p {
    color: #fff;
    font-size: 1rem;
    text-align: center;
    margin: 0;
    padding: 10px;
  }
  .skills .container{
    background-color: transparent;
    border-radius: 2rem;
    margin: 7% auto;
    height: 75%;
    width: 100%;
    max-width: 200vh;
  }

}
/******************************************************Third Page******************************************* */

.work {
  background: linear-gradient(to bottom,  rgb(118, 15, 162), rgb(0, 179, 255));
  background-size: cover;
  background-position: center;
  width: 101%;
  height: 100vh;
  padding: 10px 0;
  padding-bottom: 10%;
}
.work h3{
  color: #fff !important;
}
.work .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.work .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-work {  flex: 1 0 40%; 
  height: 35rem; 
  margin: 20px;
  border-radius: 1rem;
  transform: translate3d(0, 0, 0);
-ms-transform: translate3d(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px Solid rgba(255, 255, 255, 0.1);
  transition: box-shadow 0.3s ease, transform 0.5s ease-in-out;
  cursor: pointer;
}

.card-work:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.671);
  transform: scale(1.05);
}

.card-work-image {
  width: 80%; 
  margin-left: 3rem;
  margin-top: 1rem;
  height: 300px;
  object-fit: cover;
}

.card-work-content {
  padding: 20px;
}

.card-work-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.card-work-description {
  font-size: 1rem;
  color: #ffffff;
  text-shadow: 2px 2px 10px rgba(4, 4, 4, 0.189);
  margin-bottom: 15px;
}

.learn-more-button {
  background-color: #ffffff00;
  border: 2px solid white !important;
  border-radius: 1rem !important;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out,  color 0.5s ease-in-out;
}

.learn-more-button:hover {
  background-color: #ffffff;
  color: #000;
}
@media (max-width: 767px) {
  .work {
    display: flex;
    flex-wrap: wrap;
    height: 170vh;
    justify-content: center;
    padding-bottom: 30rem;
    padding: 15rem 2%;
  }
  .work h3{
    color: #fff;
  }
  .work .container {
    max-width: 100%;
    padding: 0 1rem;
  }
  
  .card-work {
    flex: 0 0 100%;
    height: auto;
    margin: 1rem -1rem;
    padding: 0 15px;
  }
  .card-work:hover {
    box-shadow: none;
    transform: none;
  }
  .card-work-image {
    width: 100%;
    margin: 0;
    height: auto;
  }
  
  .card-work-content {
    padding: 1rem;
  }
  
  .card-work-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .card-work-description {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .learn-more-button {
    padding: 8px 12px;
  }
}

/*********************************************************** Modal styles *********************************************************/
.modal-content {
  border-radius: 1.5rem;
  backdrop-filter: blur(10px);
  background-color: rgb(255, 255, 255);
  border: 2px Solid rgba(255, 255, 255, 0.1);;
  text-align: center;
  color: #000000;
}

.modal-header { 
  background-color: #ffffff00;
  border-bottom: none;
  text-align: center;
}

.modal-title {
  text-align: center;
  position: absolute;
  margin-top: 5rem;
  margin-left: 2rem;
  color: #000000;
}

.modal-body {
  color: #1e1e1e;
  padding: 2% 5%;
}

.modal-footer {
  background-color: #ffffff00;
  border-top: none;
}

.modal-footer button {
  background-color: #dcdcdc;
  color: #000000;
  border: 2px solid white;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.modal-footer button:hover {
  background-color: #0066ff;
  color: #ffffff;
}

.modal-footer button:focus {
  outline: none;
}

.btn-close {
  margin-top: 0.5rem !important;
  margin-right: 27rem !important;
  color: #000 !important;
}
/****************************************** Footer Styles ******************************************/

.card-start-project{
  transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
    background-color: rgba(255, 255, 255, 0.4);
    border: 2px Solid rgba(255, 255, 255, 0.1);
  width: 90%;
  height: 20%;
  padding: 2%;
  margin: -7% 5% 5% 5%;
  border-radius: 2rem;
  transition: box-shadow 0.3s ease, transform 0.5s ease-in-out;
}
.card-start-project:hover{
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.671);
  transform: scale(1.01);
}
.card-start-project h3{
  color: rgb(0, 0, 0);
  font-family: 'Michroma', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  overflow: hidden;
  position: relative;
  white-space: wrap;
  margin-top: 0rem;
  transition: margin-top 0.5s ease-in-out;
}
.btn-contact{
  color: #000;
  text-decoration: none;
}

.contact-me{
  width: 15rem;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 1rem;
  background-color:  rgba(255, 255, 255, 0);
  border: 2px solid rgb(255, 255, 255);
  color: #fff;
  transition: background-color 0.5s ease-in-out, color 0.3s ease-in-out;
}

.contact-me:hover{
  background-color: #ffffff;
  border: 2px solid white;
  color: #000;
}
.fist:hover{
  animation: jiggle 1s ease-in-out;
  animation-iteration-count: infinite;
  
}
.fist{
  position: relative;
  width: 30px; 
  height: 30px;
}
.card-start-project p{
  color: #000 !important;
}



.footer {
  background: linear-gradient(to bottom,  rgb(0, 179, 255),  rgb(118, 15, 162));
  padding: 20px;
  text-align: center;
  width: 101%;
  margin: 0 ;
  height: 70vh; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  flex: 1;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.footer-links li {
  display: inline-block;
  margin-right: 20px;
}

.footer-links a {
  text-decoration: none;
  color: #000000;
  font-size: 14px;
}

.additional-links-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  list-style: none; 
  padding: 5% 0;
}

.additional-links-list li {
  margin-right: 10px;
}

.additional-links-list a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 400;
  font-size: 1rem;
  transition: color 0.5s ease-in-out;
}

.additional-links-list a:hover {
  color: #adadad;
}


.social-icons {
  margin-top: 1rem;
  flex: 1;
  display: flex;
  justify-content: center;
}

.social-icons a {
  display: inline-block;
  margin-right: 10%;
  transition: transform 0.4s ease-in-out;
}

.social-icons a:hover {
  transform: scale(1.2);
}

.social-icons img {
  margin: 1rem 0rem 2rem 0rem;
  width: 50px;
  height: 50px;
}
.footer-logo{
  width: 100px;
  height: 100px;
}
.footer-logo:hover {
  transform: scale(1.2);
  animation: jiggle 1s ease-in-out;
  animation-iteration-count: infinite;
}


@keyframes jiggle {
  0% {
    transform: rotateZ(0deg);
  }
  20% {
    transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(10deg);
  }
  60% {
    transform: rotateZ(-10deg);
  }
  80% {
    transform: rotateZ(10deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@media (max-width: 767px) {
  .card-start-project h3{
    color: white;
    font-family: 'Michroma', sans-serif;
    font-weight: 600;
    font-size: 1.5rem !important;
    overflow: hidden;
    position: relative;
    white-space: wrap;
    margin-top: 0rem;
    transition: margin-top 0.5s ease-in-out;
  }
  .card-start-project{
    width: 95%;
    height: 30%;
    padding: 7% 5%;
    margin: -30% 2.5% 5% 2.5%;
    border-radius: 1.5rem;
  }
  .footer-content {
    flex-direction: column;
  }

  .footer-links,
  .additional-links,
  .social-icons {
    margin-bottom: 10px; 
  }
}






