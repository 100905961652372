/* navbar.css  6e00cc  ff00c3 */
.logo {
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  transition: transform 0.3s ease-in-out;
}

.logo:hover {
  transform: scale(1.2);
  animation: jiggle 1s ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes jiggle {
  0% {
    transform: rotateZ(0deg);
  }
  20% {
    transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(10deg);
  }
  60% {
    transform: rotateZ(-10deg);
  }
  80% {
    transform: rotateZ(10deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

.logo:hover {
}

.navbar {
  background-color: #FFF !important;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.265) !important;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  width: 90%;
  height: 8%;
  margin: 0% 5%;
}

.navbar-brand {
  font-weight: bold;
  color: #ffffff;
}


.navbar-nav .nav-link {
  color: #000;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  font-family: 'Gothic A1', sans-serif;
  font-weight: 600;
  transition: transform 0.3s ease-in-out;
  margin-right: 1rem;
}

.contact{
  margin-right: 2rem;
}

.navbar-nav .nav-link:hover {
  color: #0066ff;
  transform: scale(1.1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.189);
}

.navbar-toggler {
  color: #ffffff;
  border: none !important;
  background-color: transparent;
  outline: none;
  padding-top: 100px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  position: absolute;
  top: 20px;
  right: 10px;
  width: 45px;
  margin-top: 0px;
}
.navbar-toggler:focus, 
.navbar-toggler:hover,
.navbar-toggler:active {
  border: none !important;
  outline: none;
}
.navbar-toggler .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  background-color: #000;
  border: none;
  margin-bottom: 4px;
  transition: transform 0.3s ease-in-out;
}

.navbar-toggler .icon-bar:last-child {
  margin-bottom: 0;
}

.navbar-toggler.open .icon-bar:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
}

.navbar-toggler.open .icon-bar:nth-child(2) {
  opacity: 0;
}

.navbar-toggler.open .icon-bar:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
}

@media (max-width: 767px) {
  .contact{
    margin-right: 0rem;
  }
  .navbar-nav {
    flex-direction: column;
  }
  
  .navbar-toggler {
    display: block;
  } 

  .navbar-collapse {
    display: none;
  }

  .navbar-collapse.show {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-top: 1rem;
    margin-top: -0.8rem;
    border-bottom-left-radius: 1rem ;
    border-bottom-right-radius: 1rem ;
  }

  .navbar-nav .nav-link {
    margin-bottom: 10px;
    text-align: center;
    margin-right: 10rem;
  }
  .navbar {
    background-color: rgb(255, 255, 255);
    padding: 10px;
    width: 100%;
    margin: 0%;
    
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem
  }
  
  .navbar-brand {
    font-weight: bold;
    color: #ffffff;
  }
  
  .navbar-nav .nav-link {
    color: #000;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    font-family: 'Gothic A1', sans-serif;
    font-weight: 600;
    transition: transform 0.3s ease-in-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
}
